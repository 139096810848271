import { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AuthContextProvider from "./Contexts/AuthContext";
import GlobalContextProvider from "@/Contexts/GlobalContext";
import HelpContextProvider from "@/Contexts/HelpContext";
import PublicRoute from "@/Components/Route/PublicRoute";
import { Layout } from "@/Components/Layout";
import "rsuite/dist/styles/rsuite-default.css";
import "./index.css";
import "@/Resources/Styles/Flags.css";
import "./CombisForm/styles.css";
import routes from "@/routes";
import { ErrorBoundary } from "react-error-boundary";
import SigninOidc from "@/Pages/SigninOidc";
import Unauthorized from "@/Pages/Unauthorized";
import { SWRConfig } from "swr";
import { swrFetcher } from "@/Helpers/SwrFetcher";
import { ErrorFallback, SignoutOidc } from "@/Pages";
import { IntlProvider } from "rsuite";
import {
  formatUtcToDate,
  formatUtcToTime,
  formatUtcToDateAndTime,
} from "@/Helpers/DateHelper";
import { useTranslation } from "react-i18next";
import hrHR from "@/Components/UI/locales/hr_HR";
import deDE from "@/Components/UI/locales/de_DE";
import enUS from "rsuite/lib/IntlProvider/locales/en_US";
import itIT from "rsuite/lib/IntlProvider/locales/it_IT";
import SubscriptionService from "./Services/SubscriptionService";
import { SubscriptionContext } from "./Contexts/SubscriptionContext";
import axios from "axios";
import PrivateRoute from "./Components/Route/PrivateRoute";
import { slk } from "survey-core";
import { SLK } from "./Config/SurveyConfig";

const globalErrorHandler = (error, info) => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.error("Unhandled error:", error, info);
};

function App() {
  const { i18n } = useTranslation();
  const { subscription, setSubscription } = useContext(SubscriptionContext);

  useEffect(() => {
    slk(SLK);
    // getUserToken(i18n.language);
    SubscriptionService.getSubscriptionByDomainName(window.location.host)
      .then((res) => {
        setSubscription(res?.data);
        axios.defaults.headers.common["TenantId"] = res?.data?.id;
        axios.defaults.headers.common["X-Tenant-Id"] = res?.data?.id;
        document.title = `${res?.data?.name || "OCA"} - Admin `;

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = res?.data?.logo?.url;
      })
      .catch((err) => console.log("Subscription error ", err));
  }, []);

  // Don't continue without subscription
  // TenantId header is required in every axios request
  if (!subscription) return <></>;

  // onMessageListener()
  //   .then(payload => {
  //     Notification.info({
  //       title: t(payload.notification.title),
  //       description: t(payload.notification.body),
  //       className: "sc-notification sc-notification--info",
  //       placement: "bottomEnd",
  //       duration: 5000,
  //     });
  //   })
  //   .catch(err => console.error("failed receiving notification: ", err));

  const formatDate = (data, formatStr) => {
    if (formatStr.toLowerCase().includes("hh:")) {
      if (formatStr.toLowerCase().includes("yyyy")) {
        return formatUtcToDateAndTime(data, i18n.language || "hr");
      }
      return formatUtcToTime(data, i18n.language || "hr");
    }

    return formatUtcToDate(data, i18n.language || "hr");
  };

  const extractRoutes = routes
    .filter((r) => r.path)
    .concat(
      routes
        .filter((r) => r.children && r.children.length)
        .map((r) => r.children)
        .flat()
    );

  const locale =
    (i18n.language == "hr" && hrHR) ||
    (i18n.language == "de" && deDE) ||
    (i18n.language == "en" && enUS) ||
    (i18n.language == "it" && itIT) ||
    hrHR;

  return (
    <Router>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={globalErrorHandler}
      >
        <IntlProvider locale={locale} formatDate={formatDate}>
          <AuthContextProvider>
            <GlobalContextProvider>
              <SWRConfig
                value={{
                  fetcher: (url) => swrFetcher(url),
                }}
              >
                <HelpContextProvider>
                  <Switch>
                    {extractRoutes.map(
                      (
                        {
                          path,
                          Component,
                          layout,
                          isPublic,
                          roles,
                          claims,
                          featureCode,
                        },
                        key
                      ) =>
                        (!isPublic && (
                          <PrivateRoute
                            exact
                            path={path}
                            roles={roles}
                            claims={claims}
                            key={key}
                            layout={layout || Layout}
                            component={Component}
                            featureCode={featureCode}
                          />
                        )) || (
                          <PublicRoute
                            exact
                            path={path}
                            key={key}
                            layout={layout || Layout}
                            component={Component}
                          />
                        )
                    )}
                    <PublicRoute
                      exact
                      path="/unauthorized"
                      component={Unauthorized}
                      layout={(props) => <Layout {...props} />}
                    />
                    <PublicRoute
                      exact
                      path="/signin-oidc"
                      component={SigninOidc}
                      layout={(props) => <Layout hideSidebar {...props} />}
                    />
                    <PublicRoute
                      exact
                      path="/signout-oidc"
                      component={SignoutOidc}
                      layout={(props) => <Layout hideSidebar {...props} />}
                    />
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </HelpContextProvider>
              </SWRConfig>
            </GlobalContextProvider>
          </AuthContextProvider>
        </IntlProvider>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
