import {
  formApiUrl,
  formApiFormsUrl,
  formApiCategoriesUrl,
  formApiFormStatusesUrl,
  formApiRequestsUrl,
  formApiRequestStatusesUrl,
  formApiSurveysUrl,
  formApiRequestSurveysUrl,
} from "../Config/Url";
import axios from "axios";

export const formApiFormsUrlStatistics = `${formApiUrl}/statistics`;

const getForms = async (page, pageSize, status, category) => {
  return axios
    .get(`${formApiFormsUrl}`, {
      params: {
        page: page,
        take: pageSize,
        statusCode: status,
        categoryId: category,
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
};

const getFormById = async (id) => {
  return axios
    .get(`${formApiFormsUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

const createForm = async ({
  title,
  categoryId,
  formItemJson,
  formItemLifeCycleId,
  formTypeId,
}) => {
  return axios
    .post(`${formApiFormsUrl}`, {
      title,
      categoryId,
      formItemJson,
      formItemLifeCycleId,
      formTypeId,
    })
    .then((res) => res)
    .catch((err) => err.response);
};

const updateForm = async ({
  id,
  title,
  categoryId,
  formItemJson,
  formItemLifeCycleId,
  formTypeId,
}) => {
  return axios
    .put(`${formApiFormsUrl}`, {
      id,
      title,
      categoryId,
      formItemJson,
      formItemLifeCycleId,
      formTypeId,
    })
    .then((res) => res)
    .catch((err) => err.response);
};

const getRequests = async (params) => {
  return axios
    .get(`${formApiRequestsUrl}`, {
      params,
    })
    .then((res) => res)
    .catch((err) => err.response);
};

const getFormCategories = async () => {
  return axios.get(`${formApiCategoriesUrl}`);
};
const getFormCategoryById = async (id) => {
  return axios.get(`${formApiCategoriesUrl}/${id}`);
};

const createFormCategory = async (data) => {
  return axios.post(`${formApiCategoriesUrl}`, data);
};
const updateFormCategory = async (data) => {
  return axios.put(`${formApiCategoriesUrl}`, data);
};
const getFormStatuses = async () => {
  return axios.get(`${formApiFormStatusesUrl}`);
};
const deleteForm = (id) => {
  return axios.delete(`${formApiFormsUrl}/` + id);
};
const activateForm = (id) => {
  return axios.put(`${formApiFormsUrl}/` + id + "/activate");
};
const archiveForm = (id) => {
  return axios.put(`${formApiFormsUrl}/` + id + "/archive");
};

// REQUESTS
const getRequestById = async (id) => {
  return axios
    .get(`${formApiRequestsUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

const getRequestStatuses = async () => {
  return axios
    .get(`${formApiRequestStatusesUrl}`)
    .then((res) => res)
    .catch((err) => err.response);
};

const approveRequest = async (id) => {
  return axios.post(`${formApiRequestsUrl}/${id}/approve`);
};

const backToComplement = async (data) => {
  return axios.put(`${formApiRequestsUrl}/back-to-complement`, data);
};

const amendRequest = async (id) => {
  return axios.post(`${formApiRequestStatusesUrl}/${id}/amend`);
};

const discardRequest = async (id) => {
  return axios.post(`${formApiRequestStatusesUrl}/${id}/discard`);
};

const declineRequest = async (id) => {
  return axios.post(`${formApiRequestsUrl}/${id}/declined`);
};

const finalizeRequest = async (id) => {
  return axios.post(`${formApiRequestStatusesUrl}/${id}/finalize`);
};

// SURVEY
const getAllSurveys = (params) => {
  return axios.get(`${formApiSurveysUrl}`, { params });
};
const getSurveyById = (id) => {
  return axios.get(`${formApiSurveysUrl}/${id}`);
};
const createSurvey = (data) => {
  return axios.post(`${formApiSurveysUrl}`, data);
};
const updateSurvey = (data) => {
  return axios.put(`${formApiSurveysUrl}`, data);
};

const deleteSurveyById = (id) => {
  return axios.delete(`${formApiSurveysUrl}/${id}`);
};

const getAllRequestSurveys = (params) => {
  return axios.get(`${formApiRequestSurveysUrl}`, { params });
};

const getRequestSurveysBySurveyItemId = (params) => {
  return axios.get(`${formApiRequestSurveysUrl}/surveyItemId`, { params });
};

export default {
  getForms,
  getFormById,
  createForm,
  updateForm,
  getRequests,
  getRequestById,
  getFormCategories,
  getFormCategoryById,
  deleteSurveyById,
  createFormCategory,
  updateFormCategory,
  getFormStatuses,
  deleteForm,
  activateForm,
  archiveForm,
  getRequestStatuses,
  approveRequest,
  backToComplement,
  amendRequest,
  declineRequest,
  discardRequest,
  finalizeRequest,
  getAllSurveys,
  getSurveyById,
  createSurvey,
  updateSurvey,
  getAllRequestSurveys,
  getRequestSurveysBySurveyItemId,
};
