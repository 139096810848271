export const SUBSCRIPTION_FEATURE_CODE = Object.freeze({
  INVOICES: "RAC",
  SERVICES: "USL",
  TICKETING: "TIK",
  FORMS: "OBR",
  EVENT: "EVT",
  AI_ASSISTENT: "ASS",
  AI_CMS: "AICMS",
  CONTENT_LECTURE: "AICL",
  CONTENT_CREATE: "AICC",
  CONTENT_TRANSLATE: "AICT",
});
