import { useContext, useEffect } from "react";
import { oidcClientService } from "@/Services/OidcClientService";
import { useHistory } from "react-router-dom";
import { AuthContext } from "@/Contexts/AuthContext";
import Loader from "@/Components/Loader";
import axios from "axios";
import { loginUrl, tenantId } from "../../Config/Url";

function SigninOidc() {
  const history = useHistory();
  const { setLoginUser } = useContext(AuthContext);

  useEffect(() => {
    async function signinAsync() {
      // await oidcClientService.signInRedirectCallback();
      const loginUser = await oidcClientService.getUser();

      if (!loginUser) {
        setTimeout(signinAsync, 500)
        return;
      }

      if (loginUser && loginUser.profile) {
        setLoginUser({ tenantId: tenantId, ...loginUser.profile, id: loginUser.profile.sub, accessToken: loginUser.access_token, displayName: `${loginUser.profile.firstName} ${loginUser.profile.lastName}` });
        var prevLocation = sessionStorage.getItem('location');
        if (prevLocation && prevLocation.indexOf('signout-oidc') === -1) // ne smije redirectati na signout
          history.push(prevLocation);
        else
          history.push("/");
      }
    }
    signinAsync();
  }, [])

  return <Loader />;
}

export default SigninOidc;
