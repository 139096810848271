import { useState, useEffect, useContext } from "react";
import { Page } from "@/Components/Layout";
import { GlobalContext } from "@/Contexts/GlobalContext";
import { useTranslation } from "react-i18next";
import { Badge, Tag, Whisper, Tooltip } from "rsuite";
import PaymentsDashboard from "./PaymentsDashboard";
import { invoiceAdminService } from "@/Services/InvoiceAdminService";
import PaymentFilterPopup from "./PaymentFilterPopup";
import {
  format,
  addDays,
  subDays,
  startOfMonth,
  addMonths,
  endOfMonth,
} from "date-fns";
import {
  Icon,
  Popover,
  Table,
  Modal,
  Button,
  DateRangePicker,
  Dropdown,
} from "@/Components/UI";
import StatusBadge from "@/Components/StatusBadge";
import { formatUtcToDateAndTime } from "@/Helpers/DateHelper";
import { localCurrencyToAlternativeCurrency } from "@/Helpers/NumberHelper";
import "./Payments.css";
import { formatUtcToDate } from "../../Helpers/DateHelper";

const Payments = () => {
  const { t, i18n } = useTranslation();
  const { loading, setLoading, errorHandler } = useContext(GlobalContext);

  const [transactionsByMonth, setTransactionsByMonth] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [page, setPage] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [recipientModal, setRecipientModal] = useState();
  const numOfpayments = 5;

  const [filterValues, setFilterValues] = useState(null);
  const [countFilterValues, setCountFilterValues] = useState(0);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);

  const [paymentsStatusOptions] = useState([
    { id: -1, name: "All payments" },
    { id: 1, name: "Unsuccessful payments" },
    { id: 2, name: "Successful payments" },
  ]);
  const [paymentsStatus, setPaymentsStatus] = useState(-1);

  const SEARCH_KEY = "payment-search";
  const sort = "IsSuccessfull,-ExternalTransactionDate";

  const [dateRange, setDateRange] = useState([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);

  useEffect(() => {
    fetchTotal();
  }, [dateRange, filterValues]);

  useEffect(() => {
    if (filterValues) {
      let c = 0;
      Object.keys(filterValues).forEach((key) => {
        if (filterValues[key]?.length > 0) c++;
      });
      setCountFilterValues(c);
    } else {
      setCountFilterValues(0);
    }

    fetchTransaction();
  }, [page, pageSize, filterValues, dateRange, paymentsStatus]);

  const toggleFilterPopup = () => {
    setOpenFilterPopup((prevState) => !prevState);
  };
  const fetchTotal = async () => {
    setLoading(true);
    try {
      const [transactionsByMonth, vendors] = await Promise.all([
        invoiceAdminService.getTransactionsByMonth(
          addDays(dateRange[0], 1),
          addDays(dateRange[1], 1)
        ),
        invoiceAdminService.getVendors(
          addDays(dateRange[0], 1),
          addDays(dateRange[1], 1)
        ),
      ]);

      setTransactionsByMonth(transactionsByMonth.data);
      setVendors(vendors.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangePageSize = (pageSize) => {
    //Fix value for the number of page because we don`t have to remember the previous value of a page
    setPage(1);
    setPageSize(pageSize);
  };

  const isStringNull = (string) => {
    return !string || string.length === 0 ? true : false;
  };

  const statusLabel = (paidValue) => {
    return paidValue > 0 ? t("Successfully") : t("Unsuccessfully");
  };

  const paymentTimeLabel = (paidDate) => {
    return isStringNull(paidDate) ? t("No data available") : paidDate;
  };

  const handleRowClick = (data) => {
    let tempData = [...data.transactionDetailsDto];

    let modalData = (
      <div>
        <p>
          <label style={{ fontWeight: 700 }}>
            {t("External Transaction Id")}
            {": "}
          </label>
          {data.externalTransactionId}
        </p>
        <p>
          <label style={{ fontWeight: 700 }}>
            {t("External Transaction Date")}
            {": "}
          </label>
          {formatUtcToDate(`${data.externalTransactionDate}`)}
        </p>
        <p>
          <label style={{ fontWeight: 700 }}>
            {t("External Transaction Value")}
            {": "}
          </label>
          {new Intl.NumberFormat(i18n.language, {
            style: "currency",
            currencyDisplay: "narrowSymbol",
            currency: "EUR",
          }).format(data.externalTransactionValue ?? 0)}
          {/* // {data.externalTransactionValue} */}
        </p>
        <p>
          <label style={{ fontWeight: 700 }}>
            {t("Payment status")}
            {": "}
          </label>
          {data.isSuccessfull ? t("Successfully") : t("Unsuccessfully")}
        </p>
        {tempData &&
          tempData.length > 0 &&
          tempData.map((item, index) => (
            <div
              key={index}
              style={{
                borderRadius: "10px",
                border: "1px solid #f2f2f5",
                padding: "10px",
                marginTop: "10px",
                backgroundColor: index % 2 == 0 ? "#f2faff" : "",
              }}
            >
              <p>
                <label style={{ fontWeight: 700 }}>
                  {t("Recipient name")}
                  {": "}
                </label>
                {item.recipientName}
              </p>
              <p>
                <label style={{ fontWeight: 700 }}>
                  {t("OIB")}
                  {": "}
                </label>
                {item.recipientOib}
              </p>
              <p>
                <label style={{ fontWeight: 700 }}>
                  {t("Invoice number")}
                  {": "}
                </label>
                {item.invoiceNumber}
              </p>
              <p>
                <label style={{ fontWeight: 700 }}>
                  {t("Poziv na broj")}
                  {": "}
                </label>
                {item.referenceModel} {item.referenceNumber}
              </p>
              <p>
                <label style={{ fontWeight: 700 }}>
                  {t("Payer")}
                  {": "}
                </label>
                {data.payerName}
              </p>
              <p>
                <label style={{ fontWeight: 700 }}>
                  {t("Description")}
                  {": "}
                </label>
                {item.description}
              </p>

              <p>
                <label style={{ fontWeight: 700 }}>
                  {t("Paid value")}
                  {": "}
                </label>
                {new Intl.NumberFormat(i18n.language, {
                  style: "currency",
                  currencyDisplay: "narrowSymbol",
                  currency: new Date().getFullYear() >= 2023 ? "EUR" : "HRK",
                }).format(item.paidValue ?? 0)}{" "}
                {new Date().getFullYear() <= 2023 && (
                  <>
                    (
                    {new Intl.NumberFormat(i18n.language, {
                      style: "currency",
                      currency:
                        new Date().getFullYear() == 2023 ? "HRK" : "EUR",
                      currencyDisplay: "narrowSymbol",
                    }).format(
                      localCurrencyToAlternativeCurrency(item.paidValue) ?? 0
                    )}
                    )
                  </>
                )}
              </p>
            </div>
          ))}
      </div>
    );

    setRecipientModal(modalData);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const fetchTransaction = async (transactionsStatus, _dateFrom, _dateTo) => {
    setLoading(true);
    try {
      const [transactions] = await Promise.all([
        invoiceAdminService.getTransactions(
          _dateFrom ? _dateFrom : addDays(dateRange[0], 1),
          _dateTo ? _dateTo : addDays(dateRange[1], 1),
          paymentsStatus == -1 ? null : paymentsStatus,
          page,
          pageSize,
          filterValues ? filterValues.vendorId : null,
          filterValues ? filterValues.paymentName : null,
          filterValues ? filterValues.payerName : null,
          sort
        ),
      ]);

      setTransactions(transactions.data.results);
      setTotalData(transactions.data.total);

      if (transactions.data.total < pageSize && page > 1) {
        setPage(1);
      }
    } catch (error) {
      console.error("Error while loading transactions: ", error);
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnClick = () => {
    fetchTransaction();
    fetchTotal();
  };

  const handleChangeStatus = (status) => {
    let transactionsStatus = status == 1 ? false : status == 2 ? true : null;
    setPaymentsStatus(transactionsStatus);
  };

  const handleFilterClick = (filter) => {
    setFilterValues(filter);
    toggleFilterPopup();
  };

  const handleFilterClearClick = () => {
    setFilterValues(null);
    setPaymentsStatus(null);
    toggleFilterPopup();
  };

  const handleSepaExportClick = async () => {
    var res = await invoiceAdminService.sepaExport({
      vendorOib: filterValues?.vendorId,
      dateFrom: addDays(dateRange[0], 1),
      dateTo: addDays(dateRange[1], 1),
    });

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `sepa-export-${format(new Date(), "yyyy_MM_dd__HH_mm")}.xml`
    );
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const columns = [
    {
      title: t("Payer info"),
      key: "payerName",
      CustomCell: ({ value, rowData }) => (
        <div>
          <h3 className="sc-post__title">{rowData["payerName"]}</h3>

          <span className="sc-post__meta">
            {t("Number of recipients")}{" "}
            <Whisper
              trigger="hover"
              placement={"topLeft"}
              speaker={
                <Tooltip>
                  {" "}
                  {rowData["transactionDetailsDto"] &&
                  rowData["transactionDetailsDto"].length <= 1
                    ? rowData["transactionDetailsDto"].map((t) => {
                        return (
                          <p style={{ textAlign: "left" }}>{t.recipientName}</p>
                        );
                      })
                    : rowData["transactionDetailsDto"]
                        .slice(0, numOfpayments)
                        .map((d, index) => {
                          let lastRow =
                            index == numOfpayments - 1 ? (
                              <div style={{ textAlign: "left" }}>
                                {t("...And {{num}} others", {
                                  num:
                                    rowData["transactionDetailsDto"].length -
                                    numOfpayments,
                                })}
                              </div>
                            ) : (
                              ""
                            );
                          return (
                            <div
                              style={{
                                textAlign: "left",
                                fontWeight: "bold",
                                textTransform: "uppercase",
                              }}
                            >
                              {d.recipientName}
                              <br />
                              {lastRow}
                            </div>
                          );
                        })}
                </Tooltip>
              }
            >
              <Badge
                content={
                  rowData["transactionDetailsDto"]
                    ? rowData["transactionDetailsDto"].length
                    : 0
                }
                style={{
                  background: "#e6e9eb",
                  color: "#394047",
                  fontWeight: "700",
                }}
              />
            </Whisper>
          </span>
        </div>
      ),
      width: 360,
      resizable: true,
    },
    {
      title: t("Amount"),
      key: "externalTransactionValue",
      CustomCell: ({ rowData }) => (
        <div>
          <b>
            {new Intl.NumberFormat(i18n.language, {
              style: "currency",
              currencyDisplay: "narrowSymbol",
              currency: "EUR",
            }).format(rowData["externalTransactionValue"] ?? 0)}
          </b>
        </div>
      ),
      align: "right",
      width: 150,
    },
    {
      title: t("External Transaction Id"),
      key: "externalTransactionId",
      CustomCell: ({ value }) => (
        <span style={{ fontFamily: "monospace" }}>{value}</span>
      ),
      align: "center",
      width: 350,
    },
    {
      title: t("Payment date"),
      key: "externalTransactionDate",
      sortable: true,
      CustomCell: ({ rowData }) => (
        <Tag size="lg">
          {isStringNull(rowData["externalTransactionDate"])
            ? t("No data available")
            : formatUtcToDateAndTime(
                paymentTimeLabel(rowData["externalTransactionDate"])
              )}
        </Tag>
      ),
      width: 200,
      align: "center",
    },
    {
      title: t("Payment status"),
      key: "isSuccessfull",
      CustomCell: ({ rowData }) => (
        <StatusBadge
          txtContent={statusLabel(rowData["isSuccessfull"])}
          color={rowData["isSuccessfull"] ? "gray" : "red"}
        />
      ),
      width: 150,
      align: "center",
    },
  ];

  console.log("FORMAT DATE ", formatUtcToDateAndTime("2025-03-12T13:30:14Z"));

  return (
    <Page>
      <Page.Header>
        <Popover
          title={t("Filter options")}
          trigger="none"
          open={openFilterPopup}
          placement={"bottomEnd"}
          content={
            <PaymentFilterPopup
              onClear={handleFilterClearClick}
              onSubmit={handleFilterClick}
              value={filterValues}
              onClickAway={() => setOpenFilterPopup(false)}
            />
          }
        >
          <Button
            onClick={toggleFilterPopup}
            className={countFilterValues > 0 ? "sc-btn--trigger" : ""}
          >
            <Icon icon="filter" /> &nbsp; {t("Filter")}{" "}
            {countFilterValues > 0 ? `(${countFilterValues})` : ""}
          </Button>
        </Popover>
        {paymentsStatusOptions && (
          <Dropdown
            title={t("All payments")}
            initialvalue={
              paymentsStatusOptions[0] && paymentsStatusOptions[0].id
            }
            options={paymentsStatusOptions}
            labelKey="name"
            valueKey="id"
            onSelect={handleChangeStatus}
          />
        )}
        <DateRangePicker
          locale={"hr"}
          placeholder={t("Select date range")}
          placement="leftStart"
          cleanable={false}
          onChange={setDateRange}
          value={dateRange}
          ranges={[
            {
              label: t("Last 7 days"),
              value: [subDays(new Date(), 6), new Date()],
            },
            {
              label: t("Last 30 days"),
              value: [subDays(new Date(), 29), new Date()],
            },
            {
              label: t("This month"),
              value: [startOfMonth(new Date()), new Date()],
            },
            {
              label: t("Last month"),
              value: [
                startOfMonth(addMonths(new Date(), -1)),
                endOfMonth(addMonths(new Date(), -1)),
              ],
            },
          ]}
        />

        <Button onClick={handleSepaExportClick}>
          {t("Download statement")}
        </Button>

        <Button appearance="primary" onClick={handleOnClick}>
          {t("Search transactions")}
        </Button>
      </Page.Header>
      {vendors && transactionsByMonth && transactionsByMonth?.length > 0 && (
        <PaymentsDashboard
          statisticsByMonth={transactionsByMonth}
          statisticsInYear={vendors}
        />
      )}

      {/* Maybe we need this later */}
      {/* {vendors && <PaymentsStatistics props={vendors} />} */}

      <Table
        data={transactions}
        isLoading={loading}
        pagination={true}
        totalItems={totalData || 0}
        page={page}
        pageSize={pageSize}
        columns={columns}
        rowHeight={66}
        onChangePage={handleChangePage}
        onChangePageSize={handleChangePageSize}
        onRowClick={handleRowClick}
      />

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t("List of recipient")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{recipientModal}</Modal.Body>
        <Modal.Footer style={{ paddingTop: "33px" }}>
          <Button onClick={handleClose} appearance="primary">
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Page>
  );
};

export default Payments;
