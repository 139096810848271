export const sortingService = {
  getSortingOptions,
  getPostSortingOptions,
  getPageSortingOptions,
  //getTagSortingOptions,
};

async function getSortingOptions() {
  return [
    { label: "Newest", value: "-CreatedAt" },
    { label: "Oldest", value: "+CreatedAt" },
  ];
}

async function getPostSortingOptions() {
  return [
    { label: "Newest", value: "-" },
    { label: "Oldest", value: "+" },
  ];
}

async function getPageSortingOptions() {
  return [
    { label: "Newest", value: "-" },
    { label: "Oldest", value: "+" },
  ];
}
