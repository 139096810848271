import { DatePicker } from "rsuite";

const SCDatePicker = ({ oneTap = true, ...props }) => {
  return (
    <DatePicker isoWeek oneTap={oneTap} placeholder="-" {...props}>
      {props.children}
    </DatePicker>
  );
};

export default SCDatePicker;
