import { Header, Affix } from "rsuite";
import Breadcrumbs from "@/Components/Breadcrumbs";
import "./PageHeader.css";

const PageHeader = ({ title, ...props }) => {
  return (
    <div className="sc-page-header">
      <Header className="sc-page-header__content">
        <div className="sc-page-header__title">
          <h2>{<Breadcrumbs title={title} />}</h2>
        </div>
        <section className="sc-page-header__actions">{props.children}</section>
      </Header>
    </div>
  );
};

export default PageHeader;
