import {
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Radio,
  RadioGroup,
  SelectPicker,
  InputNumber,
  Toggle,
} from "rsuite";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@/Components/UI";
import "./Form.css";

const SCForm = (props) => {
  return (
    <Form {...props} className="sc-form">
      {props.children}
    </Form>
  );
};

const SCFormControl = (props) => {
  return (
    <FormControl
      {...props}
      errorMessage={
        props.errors &&
        props.errors[props.name]?.hasError &&
        props.errors[props.name].errorMessage
      }
      className="sc-form__form-control"
    >
      {props.children}
    </FormControl>
  );
};

const SCFormGroup = (props) => {
  return (
    <FormGroup {...props} className="sc-form__form-group">
      {props.children}
    </FormGroup>
  );
};

const SCFormLabel = (props) => {
  return (
    <ControlLabel {...props} className="sc-form__form-label">
      {props.children}
    </ControlLabel>
  );
};

const SCFormHelpText = (props) => {
  return (
    <HelpBlock className="sc-form__help-block" {...props}>
      {props.children}
    </HelpBlock>
  );
};

const SCTextbox = ({
  title,
  name,
  required,
  errors,
  onChange,
  handleChange,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <ControlLabel className="sc-form__form-label">{t(title)}</ControlLabel>
      <FormControl
        {...props}
        name={name}
        onChange={(value) =>
          (onChange && onChange(name, value)) ||
          (handleChange && handleChange(name, value))
        }
        errorMessage={
          errors && errors[name]?.hasError && errors[name].errorMessage
        }
      />
      {required && (
        <HelpBlock className="sc-form__help-block">{t("Required")}</HelpBlock>
      )}
    </FormGroup>
  );
};

const SCTextarea = ({
  title,
  name,
  required,
  errors,
  handleChange,
  rows,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <ControlLabel className="sc-form__form-label">{t(title)}</ControlLabel>
      <FormControl
        {...props}
        name={name}
        onChange={(value) => handleChange(name, value)}
        errorMessage={
          errors && errors[name]?.hasError && errors[name].errorMessage
        }
        rows={rows}
        componentClass="textarea"
      />
      {required && (
        <HelpBlock className="sc-form__help-block">{t("Required")}</HelpBlock>
      )}
    </FormGroup>
  );
};

const SCRadioGroup = ({
  title,
  name,
  required,
  items,
  defaultValue,
  disabled,
  errors,
  handleChange,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <ControlLabel className="sc-form__form-label">{t(title)}</ControlLabel>
      <FormControl
        {...props}
        accepter={RadioGroup}
        name={name}
        inline
        defaultValue={defaultValue}
        errorMessage={
          errors && errors[name]?.hasError && errors[name].errorMessage
        }
        onChange={(value) => handleChange(name, value)}
      >
        {items.map((t) => (
          <Radio
            key={t.value}
            value={t.value}
            disabled={disabled || t.disabled}
          >
            {t.name}
          </Radio>
        ))}
      </FormControl>
      {required && (
        <HelpBlock className="sc-form__help-block">{t("Required")}</HelpBlock>
      )}
    </FormGroup>
  );
};

const SCSelect = ({
  title,
  name,
  required,
  data,
  disabled,
  errors,
  handleChange,
}) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <ControlLabel className="sc-form__form-label">{t(title)}</ControlLabel>
      <FormControl
        block
        name={name}
        accepter={SelectPicker}
        data={data}
        disabled={disabled}
        onChange={(value) => handleChange(name, value)}
        errorMessage={
          errors && errors[name]?.hasError && errors[name].errorMessage
        }
      />
      {required && (
        <HelpBlock className="sc-form__help-block">{t("Required")}</HelpBlock>
      )}
    </FormGroup>
  );
};

const SCDatePicker = ({
  title,
  name,
  required,
  data,
  disabled,
  errors,
  handleChange,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <ControlLabel className="sc-form__form-label">{t(title)}</ControlLabel>
      <FormControl
        {...props}
        oneTap
        accepter={DatePicker}
        block
        name={name}
        data={data}
        disabled={disabled}
        onChange={(value) =>
          (onChange && onChange(value)) ||
          (handleChange && handleChange(name, value))
        }
        errorMessage={
          errors && errors[name]?.hasError && errors[name].errorMessage
        }
      />
      {required && (
        <HelpBlock className="sc-form__help-block">{t("Required")}</HelpBlock>
      )}
    </FormGroup>
  );
};

const SCToggle = ({ name, title, defaultChecked, ...props }) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <ControlLabel className="sc-form__form-label">{t(title)}</ControlLabel>
      <FormControl
        defaultChecked={defaultChecked}
        name={name}
        //onChange={(value) => onChange && onChange(name, value)}
        accepter={Toggle}
        {...props}
      />
    </FormGroup>
  );
};

const SCBadge = ({ title, color }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={`sc-form__badge sc-form__badge__${color}`}>
        {t(title)}
      </div>
    </>
  );
};

const SCInput = ({ title, name, required, onChange, errors, ...props }) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <ControlLabel className="sc-form__form-label">{t(title)}</ControlLabel>
      <FormControl
        name={name}
        {...props}
        errorMessage={
          errors && errors[name]?.hasError && errors[name].errorMessage
        }
      />
      {required && (
        <HelpBlock className="sc-form__help-block">{t("Required")}</HelpBlock>
      )}
    </FormGroup>
  );
};

const SCInputNumber = ({ title, name, required, errors, ...props }) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <ControlLabel className="sc-form__form-label">{t(title)}</ControlLabel>
      <FormControl
        name={name}
        accepter={(accepterProps) => <InputNumber {...accepterProps} />}
        {...props}
        errorMessage={
          errors && errors[name]?.hasError && errors[name].errorMessage
        }
      />
      {required && (
        <HelpBlock className="sc-form__help-block">{t("Required")}</HelpBlock>
      )}
    </FormGroup>
  );
};

SCForm.Textbox = SCTextbox;
SCForm.RadioGroup = SCRadioGroup;
SCForm.Textarea = SCTextarea;
SCForm.Select = SCSelect;
SCForm.Control = SCFormControl;
SCForm.Group = SCFormGroup;
SCForm.Label = SCFormLabel;
SCForm.HelpText = SCFormHelpText;
SCForm.DatePicker = SCDatePicker;
SCForm.Toggle = SCToggle;
SCForm.Badge = SCBadge;
SCForm.Input = SCInput;
SCForm.InputNumber = SCInputNumber;

export default SCForm;
