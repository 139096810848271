import { ControlLabel, FormControl, FormGroup, HelpBlock } from "rsuite";
import { useTranslation } from "react-i18next";

const SCTextbox = ({ title, name, required, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormGroup>
        <ControlLabel>{title}</ControlLabel>
        <FormControl name={name} {...props} />
        {required && <HelpBlock>{t("Required")}</HelpBlock>}
      </FormGroup>
    </>
  );
};

export default SCTextbox;
