import { Uploader, Alert, Loader, Icon, IconButton } from "rsuite";
import { Button } from "@/Components/UI";
import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "@/Contexts/AuthContext";
import { niceBytes } from "@/Helpers/FileHelper";
import "./ImageUploader.css";
import { GlobalContext } from "../../../Contexts/GlobalContext";

const SCImageUploader = ({
  uploadUrl,
  value,
  onChange,
  onRemoveImage,
  onUploadSuccess,
  onUploadError,
  onProgress,
  maxSizeInBytes,
  showRemoveImage,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const { loginUser } = useContext(AuthContext);
  const { errorHandler } = useContext(GlobalContext);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);

  const previewFile = (file, callback) => {
    if (maxSizeInBytes && file.size > maxSizeInBytes) {
      errorHandler(
        t("The selected file is too big (max. {{max}})", {
          max: niceBytes(maxSizeInBytes),
        })
      );
      setUploading(false);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (value) {
      if (!value.includes("http://") && !value.includes("https://")) {
        const storageImageUrl =
          (uploadUrl &&
            uploadUrl.includes("?") &&
            `${uploadUrl}&fileId=${value}`) ||
          `${uploadUrl}?fileId=${value}`;
        setImageUrl(storageImageUrl);
      } else {
        setImageUrl(value);
      }
    }
  }, [value]);

  const handleRemoveImage = () => {
    setImageUrl();
    onChange();
  };

  return (
    <div className="sc-uploader-container">
      <Uploader
        listType="picture"
        draggable
        title={"Upload image from your device"}
        accept="image/*"
        className="sc-uploader-image"
        style={
          (imageUrl && {
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }) ||
          null
        }
        fileListVisible={false}
        headers={{
          Authorization: `Bearer ${loginUser.accessToken}`,
        }}
        action={uploadUrl}
        onUpload={(file) => {
          setUploading(true);
          previewFile(file.blobFile, (value) => {
            setFileInfo(value);
          });
        }}
        onSuccess={(response, file) => {
          setUploading(false);
          //Alert.success('Uploaded successfully');
          onUploadSuccess(response, file);
          if (
            response &&
            response.success &&
            response.data &&
            response.data.length
          ) {
            onChange(response.data[0].fileGuid);
          }
        }}
        onError={(error) => {
          setFileInfo(null);
          setUploading(false);
          Alert.error("Upload failed", error);
        }}
      >
        <Button style={{ width: "unset" }}>
          {(uploading && (
            <>
              {" "}
              <span>
                <Loader /> {t("Uploading")}
              </span>
            </>
          )) || (
            <span>
              {t((imageUrl && "Upload a different image") || "Upload image")}
            </span>
          )}
        </Button>
      </Uploader>
      {showRemoveImage && imageUrl && (
        <Button
          className="sc-uploader-image__remove"
          onClick={handleRemoveImage}
        >
          {t("Remove")}
        </Button>
      )}
    </div>
  );
};

export default SCImageUploader;
