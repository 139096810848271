import { createContext, useEffect, useState } from "react";

export const SubscriptionContext = createContext();

const SubscriptionContextProvider = (props) => {
  const [subscription, setSubscription] = useState();

  const [subscriptionFeatures, setSubscriptionFeatures] = useState({});

  useEffect(() => {
    if (!subscription) return;
    const subscriptions = {};
    subscription?.subscriptionFeatures.forEach((x) => {
      subscriptions[x.featureCode] = x;
    });
    setSubscriptionFeatures(subscriptions);
  }, [subscription]);

  return (
    <SubscriptionContext.Provider
      value={{ subscription, setSubscription, subscriptionFeatures }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContextProvider;
