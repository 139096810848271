import React from "react";
import { useTranslation } from "react-i18next";
import "./WordCount.css";

const WordCount = ({ data, ...props }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="sc-word-count">
      <div className="sc-word-count__words">
        {t("Words")}: {(data && data.words) || 0}
      </div>
      <div className="sc-word-count__characters">
        {t("Characters")}: {(data && data.characters) || 0}
      </div>
    </div>
  );
};

export default WordCount;
