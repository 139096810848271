import { useState, useEffect, useContext } from "react";
import { Page } from "@/Components/Layout";
import { Dropdown, Button, Popover, Icon } from "@/Components/UI";
import Table from "@/Components/UI/Table";
import { tagService } from "@/Services";
import { useTranslation } from "react-i18next";
import { statusService } from "@/Services/StatusService";
import { pagingService } from "@/Services/PagingService";
import { sortingService } from "@/Services/SortingService";
import { useHistory } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import { formatUtcToDateAndTime, formatTimeAgo } from "@/Helpers/DateHelper";
import StatusBadge from "@/Components/StatusBadge";
import PostsFilterPopup from "./PostsFilterPopup";
import { useFilteredPosts } from "@/Hooks";
import { AuthContext } from "../../Contexts/AuthContext";
import routes from "../../routes";
import { Checkbox } from "../../Components/UI";
import { formatUtcToDate } from "../../Helpers/DateHelper";

const Posts = (props) => {
  const SEARCH_KEY = "sc-admin-web.posts-search";
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [tagOptions, setTagOptions] = useState([]);
  const [sortingOptions, setSortingOptions] = useState([
    { label: "Newest", value: "-" },
  ]);
  const [postStatusOptions, setPostStatusOptions] = useState([
    { id: -1, name: "All posts" },
  ]);

  const [postStatus, setPostStatus] = useState(() => {
    if (props.location.pathname.endsWith("drafts")) {
      return 1;
    } else if (props.location.pathname.endsWith("scheduled")) {
      return 2;
    } else if (props.location.pathname.endsWith("published")) {
      return 3;
    } else if (props.location.pathname.endsWith("external")) {
      return 4;
    } else return null;
  });

  const [onlyScheduledPosts, setOnlyScheduledPosts] = useState(
    props.location.pathname.endsWith("scheduled")
  );

  const { language } = useContext(AuthContext);

  const [pageSizeOptions, setPageSizeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFromExternalSource, setIsFromExternalSource] = useState(
    props.location.pathname.endsWith("externals")
  );
  const [tagSorting, setTagSorting] = useState("-");
  const [openFilterPopup, setOpenFilterPopup] = useState(false);

  const getSearch = () => {
    return (
      (sessionStorage.getItem(SEARCH_KEY) &&
        JSON.parse(sessionStorage.getItem(SEARCH_KEY))) ||
      null
    );
  };

  const storeSearch = () => {
    const storedSearch = getSearch();
    const newSearch = {
      ...storedSearch,
      page,
      pageSize,
      filterValues,
      postStatus,
      postSorting,
    };
    sessionStorage.setItem(SEARCH_KEY, JSON.stringify(newSearch));
  };

  const searchData = getSearch();
  const [countFilterValues, setCountFilterValues] = useState(0);

  const [filterValues, setFilterValues] = useState(
    searchData?.filterValues || null
  );
  const [page, setPage] = useState(searchData?.page || 1);
  const [pageSize, setPageSize] = useState(searchData?.pageSize || 10);
  const [postSorting, setPostSorting] = useState(
    searchData?.postSorting || "-UpdatedAt"
  );
  const { data, totalData } = useFilteredPosts({
    page,
    take: pageSize,
    ...filterValues,
    language: i18n.language,
    statusCode: postStatus,
    sort: postSorting,
    onlyScheduledPosts: onlyScheduledPosts,
    countryCode: language?.countryCode || "hr",
  });

  const newData = data.map((d) => ({
    ...d,
    title: d.title,
    lastSpace: d.title.slice(0, 80).lastIndexOf(" "),
  }));

  useEffect(() => {
    const fetchData = async () => {
      const [tags, sortings, statuses, pageSizes] = await Promise.all([
        // tagService.getTags(1, 10, 1, tagSorting),
        tagService.getTags(null, null, null, tagSorting),
        sortingService.getPostSortingOptions(),
        statusService.getPostStatusOptions(),
        pagingService.getPageSizeOptions(),
      ]);
      setTagOptions(
        (
          (tags &&
            tags.data &&
            tags.data.results &&
            tags.data.results.length && [
              ...tagOptions,
              ...tags.data.results,
            ]) || [...tagOptions]
        )
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((t) => ({ name: t.name, tagVisibilityName: t.visibilityName }))
      );

      setSortingOptions(sortings || []);

      setPostStatusOptions(
        (statuses &&
          statuses.data &&
          statuses.data.length && [
            { id: -1, name: "All posts" },
            ...statuses.data,
          ]) ||
          []
      );

      setPageSizeOptions(pageSizes);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const searchData = getSearch();

    if (searchData && searchData.postStatus !== undefined) {
      // setPostStatus(searchData.postStatus);
      if (searchData && searchData.postSorting !== undefined) {
        setPostSorting(searchData.postSorting);
      }
    }
  }, []);

  useEffect(() => {
    storeSearch();
  }, [page, pageSize, filterValues, postStatus, postSorting]);

  useEffect(() => {
    if (filterValues) {
      let c = 0;
      Object.keys(filterValues).forEach((key) => {
        if (filterValues[key]?.length > 0 || filterValues[key] === true) {
          c++;
        }
      });
      setCountFilterValues(c);
    } else {
      setCountFilterValues(0);
    }
  }, [filterValues]);

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangePageSize = (pageSize) => {
    setPageSize(pageSize);
  };

  const handleChangeStatus = (status) => {
    setPostStatus(status);
    setPage(1);
    storeSearch();
  };

  const handleRowClick = (data) => {
    history.push("/posts/editor/" + data.id);
  };

  const handleChangeSorting = (sorting) => {
    switch (postStatus) {
      case 1:
        sorting = sorting + "UpdatedAt";
        break;
      case 2:
        sorting = sorting + "UpdatedAt";
        break;
      case 3:
        sorting = sorting + "PublishedAt";
        break;
      default:
        sorting = sorting + "UpdatedAt";
    }
    setPostSorting(sorting);
    setPage(1);
  };

  useEffect(() => {
    let tempSort;
    switch (postStatus) {
      case 1:
        tempSort = postSorting.charAt(0) + "UpdatedAt";
        break;
      case 2:
        tempSort = postSorting.charAt(0) + "UpdatedAt";
        break;
      case 3:
        tempSort = postSorting.charAt(0) + "PublishedAt";
        break;
      default:
        tempSort = postSorting.charAt(0) + "UpdatedAt";
    }
    setPostSorting(tempSort);
  }, [postStatus]);

  const handleFilterClick = (filter) => {
    setFilterValues(filter);
    setPage(1);
    toggleFilterPopup();
  };

  const handleFilterClearClick = () => {
    setFilterValues();
    toggleFilterPopup();
  };

  const toggleFilterPopup = () => {
    setOpenFilterPopup((prevState) => !prevState);
  };

  const columns = [
    {
      title: t("Title"),
      key: "title",
      CustomCell: ({ value, rowData }) => (
        <div>
          {rowData.title.length >= 80 ? (
            <h3 className="sc-post__title">
              {rowData["title"].slice(0, rowData.lastSpace)}...{" "}
            </h3>
          ) : (
            <h3 className="sc-post__title">{rowData["title"]}...</h3>
          )}
          <span className="sc-page__meta">
            {t("Author")} <strong>{rowData["primaryAuthor"]}</strong> •{" "}
            {rowData["statusCode"] == 1 ||
            postStatus === null ||
            postStatus === -1
              ? t(
                  `Post: ${formatUtcToDate(
                    rowData.publishedAt
                  )} - Last edited: ${formatUtcToDate(rowData.updatedAt)}`
                )
              : rowData["statusCode"] == 2
              ? t("Scheduled")
              : t("Published")}{" "}
          </span>
        </div>
      ),

      resizable: true,
      width: 850,
    },

    {
      title: t("Status"),
      key: "status",
      CustomCell: ({ value, rowData }) => (
        <StatusBadge
          txtContent={rowData["statusName"]}
          color={
            rowData["statusName"].toLowerCase() == "draft"
              ? "red"
              : rowData["statusName"].toLowerCase() == "published"
              ? "grey"
              : "green"
          }
        />
      ),
      //flexGrow: 1,
      resizable: true,
      width: 140,
    },
    {
      title: t("Tags"),
      key: "tags",
      CustomCell: ({ value }) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {value?.map((x) => (
            <div kex={x.id}>
              <StatusBadge txtContent={x.name} color={"grey"} />
            </div>
          ))}
        </div>
      ),
      //flexGrow: 1,
      resizable: true,
      width: 145,
    },
    {
      title: t("Featured"),
      key: "featured",
      CustomCell: ({ value }) => <Checkbox checked={value} />,
      //flexGrow: 1,
      resizable: true,
      width: 85,
    },
  ];

  return (
    <Page>
      <Page.Header>
        <Popover
          title={t("Filter options")}
          trigger="none"
          open={openFilterPopup}
          placement={"bottomEnd"}
          content={
            <PostsFilterPopup
              onClear={handleFilterClearClick}
              //onChange={setFilterValues}
              onClickAway={() => setOpenFilterPopup(false)}
              onSubmit={handleFilterClick}
              value={filterValues}
              tags={tagOptions}
            />
          }
        >
          <Button
            onClick={toggleFilterPopup}
            className={countFilterValues > 0 ? "sc-btn--trigger" : ""}
          >
            <Icon icon="filter" /> &nbsp; {t("Filter")}{" "}
            {countFilterValues > 0 ? `(${countFilterValues})` : ""}
          </Button>
        </Popover>
        {postStatusOptions && (
          <Dropdown
            title={
              (postStatus &&
                postStatusOptions.find((p) => p.id == postStatus) &&
                t(postStatusOptions.find((p) => p.id == postStatus).name)) ||
              t("All posts")
            }
            initialvalue={postStatusOptions[0] && postStatusOptions[0].id}
            value={postStatus}
            options={postStatusOptions}
            labelKey="name"
            valueKey="id"
            onSelect={handleChangeStatus}
          />
        )}
        {sortingOptions && (
          <Dropdown
            title={
              (searchData &&
                searchData.postSorting &&
                sortingOptions.find((t) => t.value == searchData.postSorting) &&
                t(
                  sortingOptions.find((t) => t.value == searchData.postSorting)
                    ?.label
                )) ||
              t(sortingOptions[0].label)
            }
            initialvalue={sortingOptions[0] && sortingOptions[0].value}
            options={sortingOptions}
            labelKey="label"
            valueKey="value"
            onSelect={handleChangeSorting}
          />
        )}
        <Button
          appearance="primary"
          onClick={() => history.push("/posts/editor")}
        >
          {t("New post")}
        </Button>
      </Page.Header>

      <Table
        data={newData}
        pagination={true}
        totalItems={totalData}
        page={page}
        pageSize={pageSize}
        pageSizeMenu={pageSizeOptions}
        columns={columns}
        isLoading={isLoading}
        onChangePage={handleChangePage}
        onChangePageSize={handleChangePageSize}
        onRowClick={handleRowClick}
      />
    </Page>
  );
};

export default Posts;
