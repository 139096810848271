import axios from "axios";
import i18n from "../i18n";
import { NotificationTypes } from "../Pages/Notifications/NotificationTypes";
import { notificationsApiUrl, notificationsTenantApiUrl } from "../Config/Url";

export const notificationService = {
  createPublicNotification,
  createInternalNotification,
  createUserNotification,
  createGroupOfUsersNotification,
  getNotifications,
  getNotification,
  getNotificationTypes,
  getUserGroupNotifications,
  getPublicNotifications,
  getUserNotifications,
  getInternalNotifications,
  getNotificationsByTypeId,
  getWhereClauseByNotificationId,
  deleteNotification,
  createOrUpdateNotification,
  updateWhereClause,
  search,
  getAssignedUsers,
  getUserPreferences,
  updateUserPreferences,
  getRedirectUris,
  read,
  getMyNotifications,
  searchMyNotifications,
  updateUserFcmToken,
  sendPublicNotification,
  sendUserNotification,
  sendGroupOfUsersNotification,
  sendInternalNotification,
  setReadAllWebNotification,
};

async function createPublicNotification(
  title,
  shortDescription,
  message,
  language
) {
  return axios
    .post(`${notificationsApiUrl}/notifications/admin/public`, {
      title: title,
      shortDescription: shortDescription,
      message: message,
      language: language,
    })
    .then((res) => res);
}

async function createInternalNotification(
  title,
  shortDescription,
  message,
  language
) {
  return axios
    .post(`${notificationsApiUrl}/notifications/admin/internal`, {
      title: title,
      shortDescription: shortDescription,
      message: message,
      language: language,
    })
    .then((res) => res);
}

async function createUserNotification(
  title,
  shortDescription,
  message,
  redirectUriId,
  users,
  language
) {
  return axios
    .post(`${notificationsApiUrl}/notifications/admin/user`, {
      title: title,
      shortDescription: shortDescription,
      message: message,
      redirectUriId: redirectUriId,
      users: users,
      language: language,
    })
    .then((res) => res);
}

async function createGroupOfUsersNotification(
  title,
  shortDescription,
  message,
  usersWhereClause,
  queryJson,
  language
) {
  return axios
    .post(`${notificationsApiUrl}/notifications/admin/group`, {
      title: title,
      shortDescription: shortDescription,
      message: message,
      usersWhereClause: usersWhereClause,
      queryJson: queryJson,
      language: language,
    })
    .then((res) => res);
}

async function getNotifications(page, take, searchTerm, notificationTypeId) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin`, {
      params: { page: page, take: take, searchTerm, notificationTypeId },
    })
    .then((res) => res);
}

async function getPublicNotifications(page, take) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/public`, {
      params: { page: page, take: take },
    })
    .then((res) => res);
}

async function getUserGroupNotifications(page, take) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/group`, {
      params: { page: page, take: take },
    })
    .then((res) => res);
}

async function getUserNotifications(page, take) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/user`, {
      params: { page: page, take: take },
    })
    .then((res) => res);
}

async function getInternalNotifications(page, take) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/internal`, {
      params: { page: page, take: take },
    })
    .then((res) => res);
}

async function getServiceNotifications(page, take) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/service`, {
      params: { page: page, take: take },
    })
    .then((res) => res);
}

async function getWhereClauseByNotificationId(notificationId) {
  return axios
    .get(`${notificationsApiUrl}/notifications/where-clause`, {
      params: { notificationId: notificationId },
    })
    .then((res) => res);
}

async function getNotificationsByTypeId(notificationTypeId, page, take) {
  if (notificationTypeId && notificationTypeId === NotificationTypes.PUBLIC) {
    return await getPublicNotifications(page, take);
  }

  if (notificationTypeId && notificationTypeId === NotificationTypes.INTERNAL) {
    return await getInternalNotifications(page, take);
  }

  if (notificationTypeId && notificationTypeId === NotificationTypes.USER) {
    return await getUserNotifications(page, take);
  }

  if (
    notificationTypeId &&
    notificationTypeId === NotificationTypes.USER_GROUP
  ) {
    return await getUserGroupNotifications(page, take);
  }

  if (notificationTypeId && notificationTypeId === NotificationTypes.SERVICE) {
    return await getServiceNotifications(page, take);
  }
}

async function getNotification(id, language) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/${id}/${language}`)
    .then((res) => res);
}

async function deleteNotification(id) {
  return axios
    .delete(`${notificationsApiUrl}/notifications/admin/${id}`)
    .then((res) => res);
}

async function updateNotification(
  id,
  notificationTypeId,
  title,
  shortDescription,
  message,
  redirectUriId,
  usersWhereClause,
  queryJson,
  users,
  language
) {
  return axios
    .put(
      `${notificationsApiUrl}/notifications/admin/${
        (notificationTypeId === NotificationTypes.INTERNAL && "internal") ||
        (notificationTypeId === NotificationTypes.PUBLIC && "public") ||
        (notificationTypeId === NotificationTypes.USER && "user") ||
        (notificationTypeId === NotificationTypes.USER_GROUP && "group")
      }`,
      {
        id: id,
        title: title,
        shortDescription: shortDescription,
        message: message,
        notificationTypeId: notificationTypeId,
        redirectUriId: redirectUriId,
        usersWhereClause: usersWhereClause,
        queryJson: queryJson,
        users: users,
        language: language,
      }
    )
    .then((res) => res);
}

async function createOrUpdateNotification(
  id,
  notificationTypeId,
  title,
  shortDescription,
  message,
  redirectUriId,
  usersWhereClause,
  queryJson,
  users,
  language
) {
  if (id) {
    return await updateNotification(
      id,
      notificationTypeId,
      title,
      shortDescription,
      message,
      redirectUriId,
      usersWhereClause,
      queryJson,
      users,
      language
    );
  } else {
    if (notificationTypeId === NotificationTypes.INTERNAL) {
      return await createInternalNotification(
        title,
        shortDescription,
        message,
        language
      );
    }

    if (notificationTypeId === NotificationTypes.PUBLIC) {
      return await createPublicNotification(
        title,
        shortDescription,
        message,
        language
      );
    }

    if (notificationTypeId === NotificationTypes.USER) {
      return await createUserNotification(
        title,
        shortDescription,
        message,
        redirectUriId,
        users,
        language
      );
    }

    if (
      notificationTypeId === NotificationTypes.USER_GROUP &&
      usersWhereClause
    ) {
      return await createGroupOfUsersNotification(
        title,
        shortDescription,
        message,
        usersWhereClause,
        queryJson,
        language
      );
    }
  }
}

async function updateWhereClause(notificationId, whereClause, queryJson) {
  return axios
    .put(`${notificationsApiUrl}/notifications/where-clause`, {
      notificationId: notificationId,
      whereClause: whereClause,
      queryJson: queryJson,
    })
    .then((res) => res);
}

async function search(searchQuery, page, take) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/search`, {
      params: { page: page, take: take, query: searchQuery },
    })
    .then((res) => res);
}

async function getAssignedUsers(notificationId) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/assigned-users`, {
      params: { notificationId: notificationId },
    })
    .then((res) => res);
}

async function getNotificationTypes() {
  return [
    { name: i18n.t("Public"), id: NotificationTypes.PUBLIC },
    { name: i18n.t("Internal"), id: NotificationTypes.INTERNAL },
    { name: i18n.t("Specific user"), id: NotificationTypes.USER },
    { name: i18n.t("User group"), id: NotificationTypes.USER_GROUP },
    { name: i18n.t("Service"), id: NotificationTypes.SERVICE },
  ];
}

async function getUserPreferences(userId) {
  return axios.get(
    `${notificationsApiUrl}/notifications/admin/user-preferences/${userId}`
  );
}

async function getRedirectUris() {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/redirecturis`)
    .then((res) => res);
}

async function updateUserPreferences(data) {
  return axios.put(
    `${notificationsApiUrl}/notifications/admin/user-preferences`,
    data
  );
}

async function read(notificationId) {
  return axios
    .post(`${notificationsApiUrl}/notifications/read/${notificationId}`)
    .then((res) => res);
}

async function getMyNotifications(page, take) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/current-user`, {
      params: { page: page, take: take },
    })
    .then((res) => res);
}

async function searchMyNotifications(searchQuery, page, take) {
  return axios
    .get(`${notificationsApiUrl}/notifications/admin/current-user/search`, {
      params: { page: page, take: take, query: searchQuery },
    })
    .then((res) => res);
}

async function updateUserFcmToken({ userToken, language }) {
  return axios.post(`${notificationsApiUrl}/user-fcm-token`, {
    staleFcmToken: userToken,
    newFcmToken: userToken,
    mobileDevicePlatformId: 3, // Web
    language,
  });
}

async function sendPublicNotification(notificationId, language) {
  return axios.post(
    `${notificationsTenantApiUrl}/notifications/admin/public/send`,
    {
      notificationId: notificationId,
      language: language,
    }
  );
}

async function sendUserNotification(notificationId) {
  return axios.post(
    `${notificationsTenantApiUrl}/notifications/admin/user/send`,
    {
      notificationId: notificationId,
    }
  );
}

async function sendGroupOfUsersNotification(notificationId) {
  return axios.post(
    `${notificationsTenantApiUrl}/notifications/admin/group/send`,
    {
      notificationId: notificationId,
    }
  );
}

async function sendInternalNotification(notificationId, language) {
  return axios.post(
    `${notificationsTenantApiUrl}/notifications/admin/internal/send`,
    { notificationId: notificationId, language: language }
  );
}

async function setReadAllWebNotification() {
  return axios.post(
    `${notificationsApiUrl}/notifications/user/set-read-all-web`
  );
}
